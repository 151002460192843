@import "../colors";
@import "../settings";

.kmta__tenant--vrm-jobs {

    .kmta__headerNavWrapper {
        margin-left: -12px;
        padding-left: 0px;
    }

    a.vrm-jobs__logo {
        height: 100%;
    }

    .vrm-jobs_svgIcon--logo {
        height: 44px;
        margin-top:9px;
    }
 

    .dropdown.menu li.active > a {
        font-weight: $global-weight-bold;
        transition: all 0.2s;
    }
    .dropdown.menu .is-active > a,
    .dropdown.menu li > a:hover,
    .kmta__menu li.is-submenu-item a:hover {
        color: var(--nav-item-hover-color);
    }

    .kmta__contactperson.is-expanded {
        height: 220px;
        transition: height 0.25s ease-in;

        .is-guest & {
            height: 280px;
        }
    }

    .kmta__faqHomeInner {
        background: #9B9B9B;
        color: #FFFFFF;
    }

    .kmta__heroTitle{
        color: #FFFFFF;
    }

    .kmta__faqBoxContent {
        background-color: #FFFFFF;
    }
    .kmta__faqBoxContent p {
        color: #000000;
    }

    .kmta__faqBox {
        background-color: #FFFFFF;
    }
    .kmta__faqBox h3 {
        color:  #FFFFFF;
        background-color: #FF9F33;
    }

    .kmta__faqBoxContent .kmta__faqBoxMoreLink {
        background-color: var(--f-1-primary-color);
        border-color: var(--f-1-primary-color);
        color: #000000;
    }

    @include breakpoint(small down) {
        .kmta__headerTop {
            .kmta__tenantLogoAlt {
                display: none;
            }
            .kmta__headerUserinfo {
                margin-top: 0;
            }
        }
        .kmta__headerBottomRow {
            justify-content: space-between;
            .kmta__menu {
                justify-content: end;
                text-align: right;
            }
        }
    }
}
